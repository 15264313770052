import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/barngrindonline/gatsby/node_modules/gatsby-theme-base/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import Image from "../components/image.js";
import ProsAndCons from "../components/prosAndCons.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "spänngrindar"
    }}>{`Spänngrindar`}</h1>
    <p>{`En spänngrind är en typ av barngrind som spänns fast mellan två väggar eller i en dörröppning och hålls fast enbart av spänningen. Spänngrindar är förmodligen den vanligaste typen av barngrind idag då många vill ha en grind som de snabbt och enkelt kan montera utan att skruva i väggen. Oavsett om du vill stoppa ett barn från att gå upp för en trappa, ha ytterdörren öppen utan att ditt barn smiter, eller vill laga mat i lugn och ro utan att behöva oroa dig över att snubbla på någon som kryper runt så är en spänngrind optimal.`}</p>
    <p>{`Många andra typer av grindar skruvar man fast i väggen. En spänngrind hålls i stället uppe med hjälp av spännkraft.`}</p>
    <p>{`Den enkla installationen och avsaknaden av borrhål och du behöver därför inte skruva i väggen. Detta gör att de flesta väljer att använda denna typ av barngrind, både för mer permanenta installationer och som portabla grindar som de enkelt kan flytta runt och sätta upp eller ta ner vid behov. Kanske har du släktingar som du besöker ofta som har en brant trappa? Då är en spänngrind perfekt att ta med sig och tillfälligt gränsa av!`}</p>
    <h2 {...{
      "id": "nackdelar-med-spänngrindar"
    }}>{`Nackdelar med spänngrindar`}</h2>
    <p>{`Av texten ovan att döma så kan det låta som att spänngrindar nästan är den ultimata barngrinden. Varför skulle man inte vilja ha en enkelt, smidig och säker grind som inte lämnar några märken på väggen? Som med allt annat kommer även spänngrindar med sina nackdelar. Den största är förmodligen att en spänngrind inte kan anses vara riktigt lika säker som andra typer av grindar. Då den inte sitter skruvad i väggen, utan endast hålls uppe av spännkraft, så kan man vid tillräckligt hårt tryck lyckas lossa grinden från väggen och ett barn kan ta sig igenom.`}</p>
    <p>{`Dessutom så måste en spänngrind ha en stång i nederdelen för att fungera, och denna stång kan man enkelt snubbla på när grinden är öppen om man inte är uppmärksam. Dessa två nackdelar, att de kan råka öppnas om man trycker tillräckligt hårt samt att man kan snubbla på dem, gör att det inte är rekommenderat att ha dessa överst i en trappa. Vill du sätta en grind överst i en trappa rekommenderar vi istället en `}<Link to="/fallgrindar/" mdxType="Link">{`fällgrind`}</Link>{`.`}</p>
    <p>{`Slutligen så är öppningen på spänngrindar inte så stor. Detta är en nackdel som många inte tänker på. De breda sidostyckena och marginalerna som krävs för att spänna fast grinden gör att själva öppningen kan bli ganska smal. Detta kan göra det väldigt otympligt, framförallt om man ofta bär stolar, väskor, vagnsdelar eller annat igenom öppningen.`}</p>
    <h2 {...{
      "id": "sammanfattning"
    }}>{`Sammanfattning`}</h2>
    <ProsAndCons pros_list={["Kräver inte att man skruvar eller borrar i väggen", "Enkla att sätta upp och ta ner", "Lätta att öppna och stänga, även med en hand"]} cons_list={["Bör ej placeras överst i en trappa", "Kan glida på väggen och råka öppnas", "Snubbelrisk", "Kan lämna märken i väggen om de spänns hårt och sitter länge", "Inte så stor öppning"]} pros_header={"Fördelar"} cons_header={"Nackdelar"} mdxType="ProsAndCons" />
    <h2 {...{
      "id": "hur-installerar-man-en-spänngrind"
    }}>{`Hur installerar man en spänngrind?`}</h2>
    <p>{`Precis som namnet antyder så spänns grunden upp mot väggarna i stället för att skruvas upp. För att detta ska fundera finns alltid en "stång" längst ner som ser till att inte sidostyckena bara trycks närmare varandra. För att installera en spänngrind ställer man helt enkelt grinden i en öppning och skruvar sedan ut "plattor". I regel behöver man skruva ut på fyra ställen, uppe och nere på varje ställe. Dessa plattor spänner då mot väggen och ser till att grinden hålls på plats.`}</p>
    <p>{`I nederkant finns en stång som gör att det inte går att spänna för hårt. I överkant däremot börjar sidostyckena att böjas inåt om man spänner för hårt. För att undvika detta ska man i regel spänna grinden med grinden stängd, på själva grinden finns sedan ofta en indikering som visar när man har spänt tillräckligt. Tillexempel så spänner man tills en färg blir synlig i ett hål eller tills två streck hamnar i linje.`}</p>
    <p>{`För att skydda väggen medföljer ibland små "skålar" som man sätter mellan plattorna på grinden och väggen. Dessa skyddar då väggen från märken. Ibland finns även ett skruvhål i dessa skålar vilket gör att man skruva fast dem på väggen och spänngrinden sitter då mycket bättre. Men då har man plötsligt skruvhål i väggen.`}</p>
    <h2 {...{
      "id": "tänk-på-bredden"
    }}>{`Tänk på bredden`}</h2>
    <p>{`Tillskillnad mot en fällgrind, som man kan såga till i rätt bredd, har spänngrindar ofta en fast bredd. Det går däremot att variera bredden lite grann och ofta står det ett spann på bredden som grinden kommer passa mellan. Breddan kan varieras på tvo olika sätt.`}</p>
    <ul>
      <li parentName="ul">
        <b>Det finns viss marginal när man skruvar ut plattorna</b>. Skruvarna är ofta
ganska långa, uppemot 10 cm. Och tack vare att det finns en skruv på varje
sida kan man variera bredden runt 15 cm (man vill ha lite marginal på skruven
då den även ska fästa i grinden). Detta gör att det går att finjustera bredden
för att passa en specifik dörröppning eller trappuppgång.
      </li>
      <li parentName="ul">
        <b>Extra sidostycken</b>. Ofta får man med extra sidostycken, eller
förlängare, som man kan haka på på sidan av grinden. Dessa kommer ibland i
sektioner som är runt 5-10cm. Ju fler du sätter dit, desto bredare blir
grinden.
      </li>
    </ul>
    <h2 {...{
      "id": "spänngrind-i-en-trappa"
    }}>{`Spänngrind i en trappa`}</h2>
    <p>{`Den absolut vanligaste stället där man vill ha en barngrind är i trappan. Så hur funderar en spänngrind här? I botten av trappan är det inga som helst problem, och så länge bredde är rätt och du har väggar/stolpar att spänna den mot så borde det fungera. Högst upp i trappan rekommenderar dock de flesta tillverkare att man inte ska sätta spänngrindar, detta så spänngrindar har en stång i nederkanten som man riskerar att snubbla på när man går ner. Dessutom riskerar spänngrindar att glida mot väggarna och högst upp i trappan vill man ha en så säker grind som möjligt och man bör därför fundera på en annan typ just här.`}</p>
    <h2 {...{
      "id": "spänngrind-mot-stång"
    }}>{`Spänngrind mot stång`}</h2>
    <p>{`I vissa trappor, framför allt spiraltrappor, så sitter det runda stänger på sidorna i stället för fyrkantiga reglar eller väggar. De vanliga fästena på spänngrindar kan därför vara svåra att fästa. För att lösa detta kan man köpa en Y-klyka/Y-spindel (namnet varierar). Dessa använder man i stället platta delen på spänngrinden som spänner mot väggen. En Y-spindel är formad som ett Y i ena änden och fäster därför perfekt mot runda stolpar. De funkar även väldigt bra mot kantiga stolpar som är lite tunnare. Y-klykor gör också att grinden inte kan tryckas loss tack vare sin form, och grinden sitter då bättre än om man skulle sätta den mot en platt vägg eller stolpe.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      